/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */
//Imposto l'url del sito per le chiamate ajax
var site_url = document.location.protocol + '//' + document.location.host;

(function ($) {

    $.ajaxSetup({
        headers: {'X-CSRF-TOKEN': $('meta[name=_token]').attr('content')}
    });

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'functions': {
            xy: function () {
                var w = window,
                    d = document,
                    e = d.documentElement,
                    g = d.getElementsByTagName('body')[ 0 ],
                    x = w.innerWidth || e.clientWidth || g.clientWidth,
                    y = w.innerHeight || e.clientHeight || g.clientHeight;
                return {x: x, y: y};
            }
        },
        'common': {
            init: function () {

                var $body = $('body'),
                    $nav_primary = $('#nav-primary'),
                    $menu_item = $('.menu-item-catalogue'),
                    $hamburger = $('#nav-icon');

                $hamburger.click(function () {
                    $(this).toggleClass('open');
                    $nav_primary.toggleClass('open');
                    $body.toggleClass('menu-mobile-open');
                });
                $menu_item.click(function () {
                    var w_width = Sage.functions.xy().x;
                    if (w_width < 768) {
                        $hamburger.click();
                    }
                });

                /*$('.menu-item').click(function () {
                 $hamburger.toggleClass('open');
                 $nav_primary.toggleClass('open');
                 $body.toggleClass('menu-mobile-open');
                 });*/

            },
            finalize: function () {
                //=require _newsletter.init.js
            }
        },

        'home': {
            init: function () {

                var make_your_search = $('.home-make-search-content'),
                    make_your_search_content = $('.home-make-search-content span').text(),
                    make_your_search_content_array = make_your_search_content.split(' '),
                    new_content = '';
                for ( var i = 0; i < make_your_search_content_array.length; i++ ) {
                    new_content = new_content + '<span>' + make_your_search_content_array[ i ] + '</span>';
                }

                make_your_search.html(new_content);

                // slider header
                var $home_slider = $('.home-wall-carousel');
                $home_slider.owlCarousel({
                    nav: false,
                    dots: false,
                    loop: true,
                    items: 1,
                    margin: 0,
                    stagePadding: 0,
                    autoplay: true,
                    autoplayTimeout: 6000,
                    autoplayHoverPause: false,
                    animateOut: 'fadeOut',
                    mouseDrag: false,
                    touchDrag: false,
                    pullDrag: false,
                    freeDrag: false
                });

                $(document).on('click', 'a[href^="#"]', function (event) {
                    event.preventDefault();

                    $('html, body').animate({
                        scrollTop: $($.attr(this, 'href')).offset().top
                    }, 1000, 'swing');
                });
            },
            finalize: function () {

            }
        },

        'project_single': {
            init: function () {

                var $share_f = $('#share_f'),
                    $share_t = $('#share_t');

                $share_f.jsSocials({
                    showCount: false,
                    showLabel: true,
                    shares: [
                        {share: 'facebook', label: 'Facebook'}
                    ]
                });

                $share_t.jsSocials({
                    showCount: false,
                    showLabel: true,
                    shares: [
                        {share: 'twitter', label: 'Twitter'}
                    ]
                });

            },
            finalize: function () {

            }
        },

        'stories': {
            init: function () {

                var $share_t = $('#share_t'),
                    $share_l = $('#share_l'),
                    $share_f = $('#share_f');

                $share_t.jsSocials({
                    showCount: false,
                    showLabel: false,
                    shares: [ {
                        share: 'twitter',
                        logo: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGl2ZWxsb18xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMwcHgiIGhlaWdodD0iMzBweCIgdmlld0JveD0iMCAwIDMwIDMwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMCAzMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGcgaWQ9IkxheWVyXzNfMV8iPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0yMS42OTMsMTEuNTY2YzAuMDA1LDAuMTQ4LDAuMDExLDAuMjk5LDAuMDExLDAuNDQ5YzAsNC41NjItMy40NzQsOS44Mi05LjgyNiw5LjgyYy0xLjk0OSwwLTMuNzY2LTAuNTY3LTUuMjkxLTEuNTUxYzAuMjcxLDAuMDMsMC41NDUsMC4wNDksMC44MjQsMC4wNDljMS42MTcsMCwzLjEwNC0wLjU1Myw0LjI4Ny0xLjQ3OWMtMS41MDgtMC4wMjYtMi43ODMtMS4wMjQtMy4yMjQtMi4zOTZjMC4yMDgsMC4wNDIsMC40MjUsMC4wNjIsMC42NSwwLjA2MmMwLjMxMiwwLDAuNjItMC4wNDEsMC45MDctMC4xMjFjLTEuNTc2LTAuMzEzLTIuNzctMS43MS0yLjc3LTMuMzgyYzAtMC4wMTYsMC0wLjAyOSwwLjAwMy0wLjA0NWMwLjQ2MywwLjI2LDAuOTk3LDAuNDE0LDEuNTY0LDAuNDMyQzcuOSwxMi43ODgsNy4yOTEsMTEuNzI3LDcuMjkxLDEwLjUzYzAtMC42MzMsMC4xNy0xLjIyNSwwLjQ2Ni0xLjczNGMxLjcwMiwyLjA4OCw0LjI0OCwzLjQ2Myw3LjExNiwzLjYwN2MtMC4wNjEtMC4yNTQtMC4wODktMC41MTgtMC4wODktMC43ODljMC0xLjkwNCwxLjU0Ni0zLjQ0OSwzLjQ1LTMuNDQ5YzAuOTk2LDAsMS44OTMsMC40MTgsMi41MjEsMS4wODhjMC43ODctMC4xNTQsMS41MjctMC40MzksMi4xOTMtMC44MzhjLTAuMjYsMC44MDktMC44MDcsMS40ODItMS41MiwxLjkxMmMwLjY5OC0wLjA4MiwxLjM2NS0wLjI3LDEuOTgyLTAuNTQ1QzIyLjk1NSwxMC40NzMsMjIuMzY2LDExLjA4LDIxLjY5MywxMS41NjZMMjEuNjkzLDExLjU2NnoiLz48L2c+PC9zdmc+'
                    } ]
                });

                $share_l.jsSocials({
                    showCount: false,
                    showLabel: false,
                    shares: [ {
                        share: 'linkedin',
                        logo: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGl2ZWxsb18xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMwcHgiIGhlaWdodD0iMzBweCIgdmlld0JveD0iMCAwIDMwIDMwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMCAzMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHJlY3QgeD0iNy42MTUiIHk9IjEyLjQzOSIgZmlsbD0iIzMzMzMzMyIgd2lkdGg9IjMuMTY4IiBoZWlnaHQ9IjEwLjE4NCIvPjxjaXJjbGUgZmlsbD0iIzMzMzMzMyIgY3g9IjkuMTk5IiBjeT0iOS4yMTMiIHI9IjEuODM2Ii8+PHBhdGggZmlsbD0iIzMzMzMzMyIgZD0iTTIyLjYzNywyMi42MjNoLTMuMTY0VjE3LjY3YzAtMS4xOC0wLjAyMS0yLjY5OS0xLjY0Ni0yLjY5OWMtMS42NDYsMC0xLjg5NywxLjI4Ny0xLjg5NywyLjYxNXY1LjAzN2gtMy4xNjFWMTIuNDM5aDMuMDMzdjEuMzkzaDAuMDQ0YzAuNDIyLTAuODAxLDEuNDU0LTEuNjQ1LDIuOTk0LTEuNjQ1YzMuMjA0LDAsMy43OTcsMi4xMDcsMy43OTcsNC44NVYyMi42MjN6Ii8+PC9nPjwvc3ZnPg=='
                    } ]
                });

                $share_f.jsSocials({
                    showCount: false,
                    showLabel: false,
                    shares: [ {
                        share: 'facebook',
                        logo: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGl2ZWxsb18xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMwcHgiIGhlaWdodD0iMzBweCIgdmlld0JveD0iMCAwIDMwIDMwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMCAzMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGcgaWQ9IkxheWVyXzNfNDRfIj48cGF0aCBmaWxsPSIjMzMzMzMzIiBkPSJNMTguNzMsMTUuMDA0aC0yLjQ0djguNzAxaC0zLjYxNXYtOC43MDFoLTEuNzIxdi0zLjA3MmgxLjcyMVY5Ljk0M2MwLTEuNDIyLDAuNjc1LTMuNjQ4LDMuNjQ5LTMuNjQ4bDIuNjc4LDAuMDEydjIuOTgyaC0xLjk0M2MtMC4zMTYsMC0wLjc2OSwwLjE1OC0wLjc2OSwwLjgzOHYxLjgwOWgyLjc1N0wxOC43MywxNS4wMDR6Ii8+PC9nPjwvc3ZnPg=='
                    } ]
                });
            },
            finalize: function () {

            }
        },

        'blog': {
            init: function () {

                var $share_t = $('#share_t'),
                    $share_l = $('#share_l'),
                    $share_f = $('#share_f');

                $share_t.jsSocials({
                    showCount: false,
                    showLabel: false,
                    shares: [ {
                        share: 'twitter',
                        logo: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGl2ZWxsb18xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMwcHgiIGhlaWdodD0iMzBweCIgdmlld0JveD0iMCAwIDMwIDMwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMCAzMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGcgaWQ9IkxheWVyXzNfMV8iPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0yMS42OTMsMTEuNTY2YzAuMDA1LDAuMTQ4LDAuMDExLDAuMjk5LDAuMDExLDAuNDQ5YzAsNC41NjItMy40NzQsOS44Mi05LjgyNiw5LjgyYy0xLjk0OSwwLTMuNzY2LTAuNTY3LTUuMjkxLTEuNTUxYzAuMjcxLDAuMDMsMC41NDUsMC4wNDksMC44MjQsMC4wNDljMS42MTcsMCwzLjEwNC0wLjU1Myw0LjI4Ny0xLjQ3OWMtMS41MDgtMC4wMjYtMi43ODMtMS4wMjQtMy4yMjQtMi4zOTZjMC4yMDgsMC4wNDIsMC40MjUsMC4wNjIsMC42NSwwLjA2MmMwLjMxMiwwLDAuNjItMC4wNDEsMC45MDctMC4xMjFjLTEuNTc2LTAuMzEzLTIuNzctMS43MS0yLjc3LTMuMzgyYzAtMC4wMTYsMC0wLjAyOSwwLjAwMy0wLjA0NWMwLjQ2MywwLjI2LDAuOTk3LDAuNDE0LDEuNTY0LDAuNDMyQzcuOSwxMi43ODgsNy4yOTEsMTEuNzI3LDcuMjkxLDEwLjUzYzAtMC42MzMsMC4xNy0xLjIyNSwwLjQ2Ni0xLjczNGMxLjcwMiwyLjA4OCw0LjI0OCwzLjQ2Myw3LjExNiwzLjYwN2MtMC4wNjEtMC4yNTQtMC4wODktMC41MTgtMC4wODktMC43ODljMC0xLjkwNCwxLjU0Ni0zLjQ0OSwzLjQ1LTMuNDQ5YzAuOTk2LDAsMS44OTMsMC40MTgsMi41MjEsMS4wODhjMC43ODctMC4xNTQsMS41MjctMC40MzksMi4xOTMtMC44MzhjLTAuMjYsMC44MDktMC44MDcsMS40ODItMS41MiwxLjkxMmMwLjY5OC0wLjA4MiwxLjM2NS0wLjI3LDEuOTgyLTAuNTQ1QzIyLjk1NSwxMC40NzMsMjIuMzY2LDExLjA4LDIxLjY5MywxMS41NjZMMjEuNjkzLDExLjU2NnoiLz48L2c+PC9zdmc+'
                    } ]
                });

                $share_l.jsSocials({
                    showCount: false,
                    showLabel: false,
                    shares: [ {
                        share: 'linkedin',
                        logo: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGl2ZWxsb18xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMwcHgiIGhlaWdodD0iMzBweCIgdmlld0JveD0iMCAwIDMwIDMwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMCAzMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHJlY3QgeD0iNy42MTUiIHk9IjEyLjQzOSIgZmlsbD0iIzMzMzMzMyIgd2lkdGg9IjMuMTY4IiBoZWlnaHQ9IjEwLjE4NCIvPjxjaXJjbGUgZmlsbD0iIzMzMzMzMyIgY3g9IjkuMTk5IiBjeT0iOS4yMTMiIHI9IjEuODM2Ii8+PHBhdGggZmlsbD0iIzMzMzMzMyIgZD0iTTIyLjYzNywyMi42MjNoLTMuMTY0VjE3LjY3YzAtMS4xOC0wLjAyMS0yLjY5OS0xLjY0Ni0yLjY5OWMtMS42NDYsMC0xLjg5NywxLjI4Ny0xLjg5NywyLjYxNXY1LjAzN2gtMy4xNjFWMTIuNDM5aDMuMDMzdjEuMzkzaDAuMDQ0YzAuNDIyLTAuODAxLDEuNDU0LTEuNjQ1LDIuOTk0LTEuNjQ1YzMuMjA0LDAsMy43OTcsMi4xMDcsMy43OTcsNC44NVYyMi42MjN6Ii8+PC9nPjwvc3ZnPg=='
                    } ]
                });

                $share_f.jsSocials({
                    showCount: false,
                    showLabel: false,
                    shares: [ {
                        share: 'facebook',
                        logo: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGl2ZWxsb18xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMwcHgiIGhlaWdodD0iMzBweCIgdmlld0JveD0iMCAwIDMwIDMwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMCAzMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGcgaWQ9IkxheWVyXzNfNDRfIj48cGF0aCBmaWxsPSIjMzMzMzMzIiBkPSJNMTguNzMsMTUuMDA0aC0yLjQ0djguNzAxaC0zLjYxNXYtOC43MDFoLTEuNzIxdi0zLjA3MmgxLjcyMVY5Ljk0M2MwLTEuNDIyLDAuNjc1LTMuNjQ4LDMuNjQ5LTMuNjQ4bDIuNjc4LDAuMDEydjIuOTgyaC0xLjk0M2MtMC4zMTYsMC0wLjc2OSwwLjE1OC0wLjc2OSwwLjgzOHYxLjgwOWgyLjc1N0wxOC43MywxNS4wMDR6Ii8+PC9nPjwvc3ZnPg=='
                    } ]
                });
            },
            finalize: function () {

            }
        }

    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[ func ];
            fire = fire && typeof namespace[ func ][ funcname ] === 'function';

            if (fire) {
                namespace[ func ][ funcname ](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
